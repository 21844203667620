<template>
  <v-container fluid>
    <!-- HEADER -->
    <div class="text-center section">
      <h2 class="h2">Template Master</h2>
      <p class="text-lg font-medium text-gray-600 mb-6" style="color: grey">
        List of All Template<br />
      </p>
    </div>
    <!-- ACTION  BUTTON FOR ADDING -->

    <v-row>
      <v-col>
        <div style="width: 50%">
          <v-text-field
            v-model="searchTemplate"
            outlined
            dense
            block
            label="Search Title"
          />
        </div>
      </v-col>
      <v-col>
        <div style="float: right; margin-right: 0.1%; margin-top: 0.2%">
          <v-btn @click="dialog = !dialog" style="color: white" color="#22b2aa"
            >Add Template <v-icon small>mdi-plus</v-icon></v-btn
          >
        </div>
      </v-col>
    </v-row>

    <!-- TEMPLATE RECORD -->
    <v-row>
      <v-col>
        <v-simple-table class="tableHeight" fixed-header height="510px">
          <template v-slot:default>
            <!-- THEAD -->
            <thead>
              <tr>
                <th class="text-left" v-for="val in headers" :key="val" v-html="val" />
              </tr>
            </thead>
            <tbody v-if="searchInListOfTemplate.length">
              <tr v-for="(val, index) in searchInListOfTemplate" :key="index">
                <td v-text="val.JobKindID" />
                <td v-text="val.InquiryKindID" />
                <td v-text="val.Title" />
                <td v-html="val.Template" />

                <td
                  :style="!val.To || val.To.length < 1 ? 'color:red' : ''"
                  v-text="val.To || 'No Record Found'"
                />
                <td
                  v-if="!val.Cc || val.Cc == '[]'"
                  style="color: red"
                  v-text="'No RecordFound'"
                />
                <td v-else v-text="val.Cc" />
                <td
                  :style="!val.CategoryKind ? 'color:red' : ''"
                  v-text="val.CategoryKind || 'No Record Found'"
                />
                <td
                  :style="!val.ItemKind ? 'color:red' : ''"
                  v-text="val.ItemKind || 'No Record Found'"
                />
                <td
                  :style="!val.Status ? 'color:red' : 'color:green'"
                  v-text="val.Status || 'For Aprroval'"
                />
                <td>
                  <v-btn @click="addUser(val)" color="success" block x-small class="mb-1">
                    Add User
                  </v-btn>
                  <v-btn @click="EditRecord(val)" block color="info" x-small class="mb-1">
                    Edit
                  </v-btn>
                  <v-btn
                    @click="DeleteRecord(val)"
                    color="red"
                    block
                    style="color: white"
                    x-small
                    class="mb-1"
                  >
                    Delete
                  </v-btn>
                </td>
              </tr>
            </tbody>
            <!-- FOR NO RECORD -->
            <tbody v-else>
              <tr>
                <td
                  class="text-center"
                  colspan="9"
                  v-html="'No Record Found'"
                  style="color: red; font-weight: bold; font-size: 20px"
                />
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-dialog v-model="addUserDialog" width="80%">
      <v-card>
        <v-list v-scrollbar>
          <v-list-item-title class="ma-2" style="font-weight: bold">
            Request Additional User Template
            <!-- Request Additional User Template ON GOING DEVELOPMENT -->
          </v-list-item-title>
          <v-divider class="mb-2" />

          <span class="text--secondary ml-4"> Employee Number </span>

          <v-row>
            <v-col>
              <v-autocomplete
                class="shrink ml-4"
                v-model="InitialCode"
                :items="Users"
                item-text="EmployeeName"
                item-value="EmployeeCode"
                small-chips
                multiple
                color="#df6464"
                dense
                outlined
                style="width: 50%"
              ></v-autocomplete>
              <!-- <v-text-field
                class="shrink ml-4"
                v-model="InitialCode"
                small-chips
                multiple
                :items="Users"
                color="#df6464"
                dense
                outlined
              /> -->
            </v-col>
            <v-col cols="2">
              <v-btn color="success" dense @click="SelectedEmp()"> Add User </v-btn>
            </v-col>
          </v-row>

          <v-simple-table>
            <thead>
              <tr>
                <th>Employee Code</th>
                <th>Employee Name</th>
                <th>Email</th>
                <th>Department</th>
                <th>Section</th>
                <th>Team</th>
                <th>Postion</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(val, index) in SelectedEmployee" :key="index">
                <td>{{ val.EmployeeCode }}</td>
                <td>{{ val.EmployeeName }}</td>
                <td v-if="!val.Email" style="color: red">No Email Record</td>
                <td v-else>{{ val.Email }}</td>
                <td>{{ val.Department }}</td>
                <td>{{ val.Section }}</td>
                <td>{{ val.Team }}</td>
                <td>{{ val.Position }}</td>
              </tr>
              <tr>
                <td colspan="7">
                  <v-btn
                    :loading="loadingSaveTemp"
                    class="mt-3 ml-1"
                    block
                    dense
                    color="primary"
                    @click="saveTemplateToUser()"
                  >
                    Save User
                    <template v-slot:loader>
                      <span>Loading...</span>
                    </template>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <v-list-item class="mb-n12"> </v-list-item>
        </v-list>
        <!-- ON GOING DEVELOPMENT -->
      </v-card>
    </v-dialog>
    <!-- ADDITIONAL TEMPLATE DIALOG -->
    <v-dialog v-model="dialog" transition="slide-x-transition" max-width="600px">
      <v-card class="pa-4">
        <v-list v-scrollbar>
          <v-list-item-title class="ma-2" style="font-weight: bold">
            Request Additional Template
          </v-list-item-title>
          <v-divider class="mb-2" />
          <span class="text--secondary ml-4"
            >Title<span style="color: red" v-text="'*'" />
          </span>
          <v-list-item class="mb-n5">
            <v-text-field
              v-model="information.Title"
              color="#df6464"
              dense
              outlined
            ></v-text-field
          ></v-list-item>
          <span class="text--secondary ml-4"
            >Job Kind<span style="color: red" v-text="'*'"
          /></span>
          <v-list-item class="mb-n6">
            <v-autocomplete
              v-model="information.JobKindID"
              :items="jobkinds"
              item-text="template"
              item-value="Code"
              color="#df6464"
              dense
              outlined
            ></v-autocomplete
          ></v-list-item>
          <span class="text--secondary ml-4"
            >Inquiry Kind<span style="color: red" v-text="'*'"
          /></span>
          <v-list-item class="mb-n6">
            <v-autocomplete
              v-model="information.InquiryKindID"
              :items="inquiryKind"
              item-text="template"
              item-value="Code"
              color="#df6464"
              dense
              outlined
            ></v-autocomplete
          ></v-list-item>
          <span class="text--secondary ml-4">TO</span>
          <v-list-item class="mb-n7 mt-n1">
            <v-combobox
              v-model="information.To"
              :items="type"
              color="#df6464"
              dense
              small-chips
              outlined
              clearable
              multiple
            />
          </v-list-item>
          <span class="text--secondary ml-4">CC</span>
          <v-list-item class="mb-n6 mt-n1">
            <v-combobox
              v-model="information.Cc"
              :items="type"
              color="#df6464"
              dense
              small-chips
              outlined
              clearable
              multiple
            />
          </v-list-item>
          <span class="text--secondary ml-4">Category Kind</span>
          <v-list-item class="mb-n6">
            <v-combobox
              v-model="information.CategoryKind"
              @change="information.CategoryKind = information.CategoryKind.toUpperCase()"
              :items="categoryKind"
              color="#df6464"
              dense
              small-chips
              outlined
              clearable
            />
          </v-list-item>
          <span class="text--secondary ml-4">Item Kind</span>
          <v-list-item class="mb-n4 mt-n1">
            <v-combobox
              v-model="information.ItemKind"
              @change="information.ItemKind = information.ItemKind.toUpperCase()"
              :items="filterItemKind"
              :disabled="!information.CategoryKind"
              color="#df6464"
              dense
              small-chips
              outlined
              clearable
            />
          </v-list-item>
          <v-list-item class="mb-n4">
            <v-textarea
              v-model="template"
              @keyup="FormatText(template)"
              label="Template"
              dense
              outlined
              color="#df6464"
            ></v-textarea>
          </v-list-item>
          <v-list-item> </v-list-item>
          <v-list-item>
            <v-btn @click="SubmitTemplate(information)" color="#00cba3" block outlined
              >Submit</v-btn
            >
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <!-- <v-textarea v-model="test.test1" v-model="test.test2" /> -->
    <!-- <v-btn @click="testupdate" color="success">text</v-btn> -->
  </v-container>
</template>

<script>
import store from "@/store";
import swal from "sweetalert2";
import jobKindTable from "../../../functions/httpRequest/ExternalConnection/JobKindTable";
import inquiryKindTable from "../../../functions/httpRequest/ExternalConnection/InquiryKindTable";
import TemplateTable from "../../../functions/httpRequest/ExternalConnection/TemplateTable";
import CorrectionCategoryTable from "../../../functions/httpRequest/ExternalConnection/CorrectionCategoryTable";
import CorrectionTable from "../../../functions/httpRequest/ExternalConnection/CorrectionRequestTable";
import correctionRequestTable from "../../../functions/httpRequest/ExternalConnection/CorrectionRequestTable";
import other from "../../../functions/alert";
import moment from "moment";
// import Others from "../../../functions/alert";
export default {
  data() {
    return {
      loadingSaveTemp: false,
      updateTemplate: {},
      InitialCode: "",
      SelectedEmployee: [],
      addUserDialog: false,
      searchTemplate: "",
      test: {},
      dialog: false,
      toBool: false,
      ccBool: false,
      forUpdate: false,
      toUpdate: false,
      Department: store.state.userinfo.Department,
      template: "",
      information: { To: [], Cc: [] },
      addMultiple: {},
      additionalTo: [],
      deletedTemplate: {},
      jobkinds: [],
      inquiryKind: [],
      templates: [],
      categoryKind: [],
      itemKind: [],
      type: [
        "Planner",
        "Salesman",
        "First",
        "Middle",
        "Plumber",
        "ExteriorSubcon1st",
        "ExteriorSubcon2nd",
        "PlannerLeader",
        "DesignOffice",
      ],
      headers: [
        "Job Kind",
        "Inquiry Kind",
        "Title",
        "Template",
        "To",
        "Cc",
        "Category Kind",
        "Item Kind",
        "Status",
        "Action",
      ],
      Users: [],
    };
  },
  async created() {
    this.information.Department = this.Department;
    await this.GetJobKind();
    await this.GetInquiryKind();
    await this.GetTemplate();
    await this.CategoryItemKind(this.Department);
  },
  computed: {
    filterItemKind() {
      return this.itemKind.length
        ? this.itemKind
            .filter((el) => el.Category == this.information.CategoryKind)
            .map((item) => item.ItemKind)
        : [];
    },
    searchInListOfTemplate() {
      if (this.searchTemplate) {
        return this.templates.filter((element) => {
          return (
            element.InquiryKindID.toUpperCase().includes(
              this.searchTemplate.toUpperCase()
            ) ||
            element.JobKindID.toUpperCase().includes(this.searchTemplate.toUpperCase()) ||
            element.Title.toUpperCase().includes(this.searchTemplate.toUpperCase()) ||
            element.Template.toUpperCase().includes(this.searchTemplate.toUpperCase())
          );
        });
      } else {
        return this.templates;
      }
    },
  },
  methods: {
    async SelectedEmp() {
      await CorrectionTable.Get().then((res) => {
        // console.log(this.InitialCode);
        for (let i = 0; i < this.InitialCode.length; i++) {
          const element = this.InitialCode[i];
          var emp = res.data.filter((el) => {
            if (element == el.EmployeeCode) {
              // console.log(el);
              return el;
            }
          });

          let ifExist = this.SelectedEmployee.filter((item) => {
            if (element == item.EmployeeCode) {
              return item;
            }
          });
          if (ifExist.length > 0) {
            // console.log(ifExist.length);
            // alert("Employee is already exist");
          } else {
            this.SelectedEmployee.push(emp[0]);
          }
        }
      });
    },

    saveTemplateToUser() {
      // if (!this.SelectedEmployee.length) {
      //   alert("Please Add Employee First");
      //   return false;
      // }
      // this.loadingSaveTemp = true;

      // let a = this.SelectedEmployee.map((res) => {
      //   let Inquiry = JSON.parse(res.Inquiry);
      //   let Job = JSON.parse(res.Job);
      //   let Template = JSON.parse(res.Template);

      //   let inquiryTrap = Inquiry.findIndex(
      //     (res) => res == this.updateTemplate.InquiryKindID
      //   );
      //   inquiryTrap == -1
      //     ? Inquiry.push(this.updateTemplate.InquiryKindID)
      //     : "";

      //   let JobTrap = Job.findIndex(
      //     (res) => res == this.updateTemplate.JobKindID
      //   );
      //   JobTrap == -1 ? Job.push(this.updateTemplate.JobKindID) : "";

      //   let TemplateTrap = Template.findIndex(
      //     (res) => res == this.updateTemplate.id
      //   );
      //   TemplateTrap == -1 ? Template.push(this.updateTemplate.id) : "";
      //   // Template.push(this.updateTemplate.id)
      //   return {
      //     ...res,
      //     Inquiry: Inquiry,
      //     Job: Job,
      //     Template: Template,
      //   };
      // });
      // console.log(a, "Updated User Info");
      // let b = [];
      // a.forEach((item) => {
      //   b.push(correctionRequestTable.UpdateUserTemplate(item));
      // });
      // Promise.all(b).then((valReturn) => {
      //   if (valReturn) {
      //     this.loadingSaveTemp = false;
      //     this.SelectedEmployee = [];
      //     this.addUserDialog = false;
      //   }
      // });
      // //  correctionRequestTable
      // // .UpdateUserTemplate(a)
      // // .then(() => alert("New Template added in user"));
      // // this.SelectedEmployee = []

      if (!this.SelectedEmployee.length) {
        alert("Please Add Employee First");
        return false;
      }
      this.loadingSaveTemp = true;

      let a = this.SelectedEmployee.map((res) => {
        // console.log(JSON.parse(res.Job));

        let Inquiry = JSON.parse(res.Inquiry);
        let Job = JSON.parse(res.Job);
        let Template = JSON.parse(res.Template);

        if (!Inquiry || !Job || !Template) {
          Inquiry = [];
          Job = [];
          Template = [];
        }

        if (Inquiry) {
          let inquiryTrap = Inquiry.findIndex(
            (res) => res == this.updateTemplate.InquiryKindID
          );
          inquiryTrap == -1 ? Inquiry.push(this.updateTemplate.InquiryKindID) : "";
        }

        if (Job) {
          let JobTrap = Job.findIndex((res) => res == this.updateTemplate.JobKindID);
          JobTrap == -1 ? Job.push(this.updateTemplate.JobKindID) : "";
        }

        if (Template) {
          let TemplateTrap = Template.findIndex((res) => res == this.updateTemplate.id);
          TemplateTrap == -1 ? Template.push(this.updateTemplate.id) : "";
        }

        return {
          ...res,
          Inquiry: Inquiry,
          Job: Job,
          Template: Template,
        };
      });

      // console.log(a, "Updated User Info");
      alert("Save Sucessfully");
      let b = [];
      a.forEach((item) => {
        b.push(correctionRequestTable.UpdateUserTemplate(item));
      });
      Promise.all(b).then((valReturn) => {
        if (valReturn) {
          this.loadingSaveTemp = false;
          this.SelectedEmployee = [];
          this.addUserDialog = false;
        }
      });
      //  correctionRequestTable
      // .UpdateUserTemplate(a)
      // .then(() => alert("New Template added in user"));
      // this.SelectedEmployee = []
    },
    addUser(TemplateVal) {
      this.SelectedEmployee = [];
      this.InitialCode = "";
      // console.log(TemplateVal)
      this.updateTemplate = { ...TemplateVal };
      this.addUserDialog = true;
      CorrectionTable.Get().then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          if (
            element.Department == store.state.userinfo.Department &&
            element.Section == store.state.userinfo.Section
          ) {
            // console.log(element);
            this.Users.push(element);
          }
        }

        // this.Users = res.data.map((check) => {
        //   if (
        //     check.Department == store.state.userinfo.Department &&
        //     check.Section == store.state.userinfo.Section
        //   ) {
        //     // return check;
        //     // &&
        //     // Others.EmployeePicture.Picture(check.EmployeCode);
        //   }
        // });
        // console.log(this.Users)
        // this.Users = res.data.filter(
        //     (check) =>
        //       check.Department == store.state.userinfo.Department &&
        //       check.Section == store.state.userinfo.Section
        //   );
        // this.Users = Object.assign(listOfEmp)
        // console.log(this.Users)
      });
    },

    DeleteRecord({ ...val }) {
      // console.log(val, "513");
      swal
        .fire({
          title: "Are you sure you want to delete?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            swal.fire("Deleted!", "The template has been temporary deleted.", "success");
            this.templates.filter((element) => {
              if (element.id == val.id) {
                let index = this.templates.indexOf(element);
                this.deletedTemplate = this.templates.splice(index, 1);
              }
            });
            let newInfoObjectDelete = {
              CategoryKind: val.CategoryKind || null,
              Cc: val.Cc,
              CreatedBy: val.CreatedBy,
              DateCreated: val.DateCreated,
              Department: val.Department,
              InquiryKindID: val.InquiryKindID,
              ItemKind: val.ItemKind || null,
              JobKindID: val.JobKindID || null,
              Status: val.Status,
              Template: val.Template,
              Title: val.Title,
              To: val.To,
              UpdatedBy: val.UpdatedBy,
              deletedDate: val.deletedDate,
              deletedBy: store.state.userinfo.FullName,
              id: val.id,
            };
            TemplateTable.Delete(newInfoObjectDelete).then(() => {});
          }
        });
    },

    testupdate() {
      const recip = this.test.test1.split("\n");
      const email = this.test.test2.split("\n");

      const check = [];
      for (let x in recip)
        check.push({
          To: recip[x],
          Email: email[x],
        });
      // console.log(JSON.stringify(check));
    },
    EditRecord(val) {
      // console.log(val);
      // console.log(JSON.parse(val.To))
      let jsonTo = JSON.parse(val.To);
      let jsonCc = JSON.parse(val.Cc);

      // let editArray = [];
      // editArray = val
      val.To = jsonTo;
      val.Cc = jsonCc;
      // // let toArr = val.To.filter((rec)=>{
      //   return rec
      // })
      // console.log(toArr)
      // val.JobKindID = this.jobkinds.filter(
      //   (el) => el.JapaneseName == val.JobKindID
      // )[0];
      // val.InquiryKindID = this.inquiryKind.filter(
      //   (el) => el.EnglishName == val.InquiryKindID
      // )[0];
      this.template = val.Template.split("<br>").join("\n");

      this.information = { ...val };
      this.toUpdate = !this.toUpdate;
      this.dialog = !this.dialog;
    },

    AddRecipient(val, type) {
      this.information[type].push(val);
      this.addMultiple[type] = "";
    },
    SubmitTemplate({ ...info }) {
      // console.log(info);
      // if (Object.keys(info).length < 7)
      //   return this.$toast.error("Fill-up all required field", other.alert);

      // FOR UPDATE
      if (this.toUpdate) return this.UpdateRecord(info);
      // CHECK CATEGORY
      let Item = [0];
      let Category = [0];

      if (this.categoryKind.length)
        Category = this.categoryKind.filter((el) => info.CategoryKind == el);
      // CHECK ITEM
      if (this.itemKind.length)
        Item = this.itemKind.filter((el) => info.ItemKind == el.ItemKind);
      // FOR INSERTING CATEGORY
      const InsertCategory = async (Category, Department) =>
        await CorrectionCategoryTable.InsertCategory({
          Category: Category,
          Department: Department,
        });
      // FOR INSERTING ITEM
      const InsertItem = async (Category, ItemKind, Department) =>
        await CorrectionCategoryTable.InsertItemKind({
          Category: Category,
          ItemKind: ItemKind,
          Department: Department,
        });

      // CHECK IF EMPTY CATEGORY
      if (!Category.length || (!this.categoryKind.length && info.CategoryKind)) {
        InsertCategory(info.CategoryKind, this.Department);
        InsertItem(info.CategoryKind, info.ItemKind, this.Department);
      } else if (!Item.length || (!this.itemKind.length && info.ItemKind))
        InsertItem(info.CategoryKind, info.ItemKind, this.Department);

      info.To = info.To ? JSON.stringify(info.To) : null;
      info.Cc = info.Cc ? JSON.stringify(info.Cc) : null;

      let newInfoObject = {
        Cc: info.Cc || null,
        Department: info.Department,
        InquiryKindID: info.InquiryKindID,
        JobKindID: info.JobKindID,
        Title: info.Title,
        To: info.To,
        ItemKind: info.ItemKind || null,
        CategoryKind: info.CategoryKind || null,
        CreatedBy: store.state.userinfo.FullName,
        Template: info.Template,
        deletedDate: null,
      };
      // console.log(newInfoObject, "644");
      TemplateTable.InsertTemplate(newInfoObject).then(() => {
        this.GetTemplate();
        this.CategoryItemKind(this.Department);
        this.template = "";
        this.information = {
          Department: this.Department,
          To: ["Planner"],
          Cc: [],
          Title: newInfoObject.Title,
          JobKindID: newInfoObject.JobKindID,
          InquiryKindID: newInfoObject.InquiryKindID,
          CategoryKind: newInfoObject.CategoryKind,
        };
        this.dialog = !this.dialog;
        this.$toast.success("Insert Successfully", other.alert);
      });
    },
    UpdateRecord({ ...info }) {
      // CHECK CATEGORY
      // console.log(info);

      let Item = [0];
      let Category = [0];

      if (this.categoryKind.length)
        Category = this.categoryKind.filter((el) => info.CategoryKind == el);
      // CHECK ITEM
      if (this.itemKind.length)
        Item = this.itemKind.filter((el) => info.ItemKind == el.ItemKind);
      // FOR INSERTING CATEGORY
      const InsertCategory = async (Category, Department) =>
        await CorrectionCategoryTable.InsertCategory({
          Category: Category,
          Department: Department,
        });
      // FOR INSERTING ITEM
      const InsertItem = async (Category, ItemKind, Department) =>
        await CorrectionCategoryTable.InsertItemKind({
          Category: Category,
          ItemKind: ItemKind,
          Department: Department,
        });

      // CHECK IF EMPTY CATEGORY
      if (!Category.length || (!this.categoryKind.length && info.CategoryKind)) {
        InsertCategory(info.CategoryKind, this.Department);
        InsertItem(info.CategoryKind, info.ItemKind, this.Department);
      } else if (!Item.length || (!this.itemKind.length && info.ItemKind))
        InsertItem(info.CategoryKind, info.ItemKind, this.Department);

      info.To = info.To ? JSON.stringify(info.To) : null;
      info.Cc = info.Cc ? JSON.stringify(info.Cc) : null;
      info.DateCreated = info.DateCreated
        ? moment(info.DateCreated).format("YYYY-MM-DD hh:mm:ss")
        : null;
      info.deletedDate = info.deletedDate
        ? moment(info.deletedDate).format("YYYY-MM-DD hh:mm:ss")
        : null;

      let newInfoObjectUpdate = {
        Cc: info.Cc,
        Department: info.Department,
        InquiryKindID: info.InquiryKindID,
        JobKindID: info.JobKindID,
        Title: info.Title,
        To: info.To,
        ItemKind: info.ItemKind || null,
        CategoryKind: info.CategoryKind || null,
        CreatedBy: info.CreatedBy,
        UpdatedBy: store.state.userinfo.FullName,
        Template: info.Template,
        deletedDate: info.deletedDate,
        DateCreated: info.DateCreated,
        DateUpdated: moment().format("YYYY-MM-DD hh:mm:ss"),
        id: info.id,
        Status: info.Status,
      };
      // console.log(info, "716");
      // console.log(newInfoObjectUpdate);
      TemplateTable.Update(newInfoObjectUpdate).then(() => {
        this.GetTemplate();
        this.CategoryItemKind(this.Department);
        this.template = "";
        this.information = {
          Department: this.Department,
          To: [],
          Cc: [],
        };
        this.dialog = !this.dialog;
        this.toUpdate = !this.toUpdate;
        this.$toast.success("Update Successfully", other.alert);
      });
    },
    FormatText(text) {
      this.information.Template = text.split("\n").join("<br>");
    },
    async GetJobKind() {
      await jobKindTable.Get().then((res) => {
        for (let x of res.data)
          x.template = `${x.Code} - ( ${x.EnglishName} / ${x.JapaneseName} )`;
        this.jobkinds = res.data;
      });
    },
    async GetInquiryKind() {
      await inquiryKindTable.Get().then((res) => {
        for (let x of res.data)
          x.template = `${x.Code} - ( ${x.EnglishName} / ${x.JapaneseName} )`;
        this.inquiryKind = res.data;
      });
    },
    async GetTemplate() {
      await TemplateTable.GetDepartment(this.Department).then((res) => {
        // console.log(res.data)
        // console.log(this.Department)
        let displayTemplate = res.data.filter((res) => {
          return res;
        });
        // for (let x of res.data) {
        //   x.InquiryKindID = this.inquiryKind.filter(
        //     (el) => el.Code == x.InquiryKindID
        //   )[0].EnglishName;
        //   x.JobKindID = this.jobkinds.filter(
        //     (el) => el.Code == x.JobKindID
        //   )[0].JapaneseName;
        //   x.To = x.To ? JSON.parse(x.To).join(", ") : "";
        //   x.Cc = x.Cc ? JSON.parse(x.Cc).join(", ") : "";
        // }

        this.templates = displayTemplate;
      });
    },
    async CategoryItemKind(department) {
      await CorrectionCategoryTable.GetCategory(department).then(
        (res) =>
          (this.categoryKind = res.data.length ? res.data.map((el) => el.Category) : [])
      );
      await CorrectionCategoryTable.GetItemKind(department).then(
        (res) => (this.itemKind = res.data)
      );
    },
  }, //end methods

  watch: {}, //end watch
};
</script>

<style scoped>
.v-text-field {
  width: 200px;
}
</style>
