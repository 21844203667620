import axios from "axios";
import keys from "../../alert";

const baseUrl = keys.auth.BaseUrl;
const apikey = keys.auth.apiKey;

const httpRequest = {
  //*************************************//
  //       GET TEMPLATE ROUTES           //
  //*************************************//
  InsertTemplate(data) {
    return Promise.resolve(
      axios.post(`${baseUrl}/template`, { data: data }, apikey)
    );
  },
  //*************************************//
  //       GET SPECIFIC ROUTES           //
  //*************************************//
  GetSpecific(data) {
    return Promise.resolve(
      axios.get(`${baseUrl}/template`, { params: data }, apikey)
    );
  },

  //***************************************//
  //           GET USER ROUTES            //
  //***************************************//
  GetUser(template) {
    return axios.get(`${baseUrl}/usertemplate`, {
      headers: { "x-api-key":"ZUe7gbLExb2OyqUHGdwXT9r8whee39ivabgYIXdJ"},
      params: template
    });
  },

  //***************************************//
  //       GET DEPARTMENT ROUTES           //
  //***************************************//
  GetDepartment(department) {
    return Promise.resolve(
      axios.get(`${baseUrl}/template/${department}`, apikey)
    );
  },
  //*************************************//
  //       GET TEMPLATES ROUTES          //
  //*************************************//
  Get() {
    return Promise.resolve(axios.get(`${baseUrl}/template`, apikey));
  },
  //*************************************************//
  //       GET TEMPLATES INFORMATION ROUTES          //
  //*************************************************//

  GetInformation() {
    return Promise.resolve(axios.get(`${baseUrl}/templateInformation`, apikey));
  },

  //*************************************************//
  //       GET TEMPLATES INFORMATION ROUTES          //
  //*************************************************//

  Update(data) {
    return Promise.resolve(
      axios.put(`${baseUrl}/template`, { data: data }, apikey)
    );
  },
  //*************************************************//
  //       GET TEMPLATES INFORMATION ROUTES          //
  //*************************************************//

  Delete(data) {
    return Promise.resolve(
      axios.put(`${baseUrl}/templateDelete`, { data: data }, apikey)
    );
  },
};

export default httpRequest;
